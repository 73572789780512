import { ConnectWallet } from "@thirdweb-dev/react"
import { Link } from "react-router-dom";
import logo from "../assets/logo.png"

const Header = () => {
    return (
        <div class="flex justify-between content-start px-4 md:mx-40 my-3">

            {/* web3starterkit logo */}

            <p class="text-gray-500 text-3xl">Lombong Labs Technologies</p>

            {/* <img src={logo} class="h-12 md:h-16" /> */}

            {/* connect wallet and navigation */}

            <div class="flex items-center space-x-6">

                <Link to="/" class="font-medium text-gray-700">Mint</Link>

                <Link to="/claim" class="font-medium text-gray-700">Claim</Link>

                <ConnectWallet theme="light" className="md:h-10 md:px-8 md:ml-4" />

            </div>

        </div>
    );
}

export default Header;