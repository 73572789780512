import Footer from "../components/Footer";
import Header from "../components/Header";

const About = () => {
    return (
        <div>

            <Header />

            {/* self shill */}

            <div class="flex flex-col items-center space-y-6 my-40 justify-center">

                <p class="text-slate-600 text-4xl">Lombong Labs Technologies</p>
                <p class="text-slate-600">Registration number: 202303227775 (IP0589240-P)</p>

                {/* kit as a service */}

                <a href="https://eips.ethereum.org/EIPS/eip-6551" target="_blank" class="border border-2 border-slate-600 rounded-md px-4 py-2">Learn more about ERC6551</a>

            </div>

            <Footer />

        </div>
    );
}

export default About;