import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div class="flex justify-between bg-white px-4 md:px-40 py-4 fixed inset-x-0 bottom-0">

            {/* socials and about link */}

            <div class="flex space-x-4">
                <Link to="/about" class="text-gray-500 text-sm">about</Link>
                <a href="https://twitter.com/web3starterkit" target="_blank" class="text-gray-500 text-sm">twitter (X)</a>
            </div>

            {/* copyright */}

            <p class="text-gray-500 text-sm">© Lombong Labs Technologies 202303227775 (IP0589240-P)</p>

        </div>
    );
}

export default Footer;